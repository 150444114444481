<template>
  <div>
    <div>
      <div class="selo-branco-menu" v-bind:class="seloMapa">
        <img src="@/assets/selo-mapa.svg" />
      </div>
      <div class="menu" v-bind:class="menuPage">
        <div class="content horizon-bar menu-opcoes">
          <RouterLink
            class="item-menu"
            @click.native="changeMenuFocus();
            closeDropdownMenuOthers()"
            to="/"
          >
            <span class="material-icons md-18">reply</span>
            <span class="label-menu">voltar</span>
          </RouterLink>

          <RouterLink
            v-bind:class="menuOptions.resumo"
            class="item-menu"
            @click.native="changeMenuFocus();
            closeDropdownMenuOthers()"
            :to="{ path: '/resumo', query: queryParams}"
          >
            <span class="material-icons md-18">import_contacts</span>
            <span class="label-menu">resumo</span>
          </RouterLink>

          <RouterLink
            v-bind:class="menuOptions.participacaoCidada"
            class="item-menu"
            @click.native="changeMenuFocus();
            closeDropdownMenuOthers()"
            :to="{ path: '/participacao-cidada', query: queryParams}"
          >
            <span class="material-icons md-18">supervisor_account</span>
            <span class="label-menu">participação cidadã <br /> <span class="gestao-resultado">e gestão por resultado</span></span>
          </RouterLink>

          <div
            v-bind:class="menuOptions.resultadosSistemicos"
            class="item-menu"
            v-on:click="changeMenuFocus();
            dropdownResultadosSistemicos()"
          >
            <span class="material-icons md-18">face</span>
            <span class="label-menu">resultados sistêmicos</span>
            
            <div
              class="dropdown-resultados-sistemicos"
              v-bind:class="stateDropdownResultadosSistemicos"
            >
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-01', query: queryParams}"
                  class="option-dropdown desenvolvimento-infantil"
                >
                  Desenvolvimento infantil e na primeira infância
                </RouterLink>
              </span>
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-02', query: queryParams}"
                  class="option-dropdown educacao"
                >
                  Educação de qualidade para todos
                </RouterLink>
              </span>
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-03', query: queryParams}"
                  class="option-dropdown habitos"
                >
                  Hábitos de higiene e acesso a água e saneamento assegurados para crianças e adolescentes nas escolas
                </RouterLink>
              </span>
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-04', query: queryParams}"
                  class="option-dropdown oportunidades"
                >
                  Oportunidades de educação trabalho e formação professional para adolescentes e jovens
                </RouterLink>
              </span>
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-05', query: queryParams}"
                  class="option-dropdown desenvolvimento-integral"
                >
                  Desenvolvimento integral, saúde mental, e bem-estar de crianças e adolescentes na segunda década de vida
                </RouterLink>
              </span>
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-06', query: queryParams}"
                  class="option-dropdown prevencao"
                >
                  Prevenção e resposta às violências contra crianças e adolescentes
                </RouterLink>
              </span>
              <span v-on:click="closeDropdownMenuSelf()">
                <RouterLink
                  :to="{ path: '/resultados-sistemicos-07', query: queryParams}"
                  class="option-dropdown protecao"
                >
                  Proteção social e atenção integral para famílias vulneráveis via serviços intersetoriais
                </RouterLink>
              </span>
            </div>
          </div>
          <RouterLink
            v-bind:class="menuOptions.indicadoresSociais"
            class="item-menu"
            @click.native="changeMenuFocus();
            closeDropdownMenuOthers()"
            :to="{ path: '/indicadores-sociais', query: queryParams}"
          >
            <span class="material-icons md-18">bar_chart</span>
            <span class="label-menu">indicadores sociais</span>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {
      urlParams: this.$route.query,
      queryParams: {},
      stateDropdownResultadosSistemicos: "hide-dropdown",
      seloMapa: null,
      menuPage: null,
      menuOptions: {
        resumo: null,
        participacaoCidada: null,
        resultadosSistemicos: null,
        indicadoresSociais: null,
      },
    };
  },
  mounted() {
    this.queryParams = this.urlParams.uf ? this.cleanQueryParams(this.urlParams.uf, this.urlParams.municipio) : null;
    this.changeMenuFocus();

    const scrollMenu = document.querySelector(".menu-opcoes");

    scrollMenu.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollMenu.scrollLeft += evt.deltaY;
    });
  },
  watch:{
    $route (to){
      if (to.path === "/") {
        this.seloMapa = "show-item";
        this.menuPage = "hide-item";
      } else {
        this.seloMapa = "hide-item";
        this.menuPage = "show-item";
      }
    }
  },
  methods: {
    cleanQueryParams(uf, municipio) {
      const ufReplaced = uf.toLowerCase();
      const municipioReplaced = municipio.toLowerCase().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return {uf: ufReplaced, municipio: municipioReplaced};
    },
    changeRoute(e) {
      this.$router.push("/" + e.target.value);
    },
    changeMenuFocus() {
      const rotaAtual = this.$router.currentRoute.path.toString();

      this.seloMapa = rotaAtual === "/" ? "show-item" : "hide-item";
      this.menuPage = rotaAtual !== "/" ? "show-item" : "hide-item";
      this.menuOptions.resumo = rotaAtual === "/resumo" ? "nav-link-selected" : "";
      this.menuOptions.participacaoCidada = rotaAtual === "/participacao-cidada" ? "nav-link-selected" : "";
      this.menuOptions.resultadosSistemicos = rotaAtual === "/resultados-sistemicos" ? "nav-link-selected" : "";
      this.menuOptions.indicadoresSociais = rotaAtual === "/indicadores-sociais" ? "nav-link-selected" : "";
    },
    dropdownResultadosSistemicos() {
      if (this.stateDropdownResultadosSistemicos === "hide-dropdown") {
        this.stateDropdownResultadosSistemicos = "show-dropdown";
      } else (
        this.stateDropdownResultadosSistemicos = "hide-dropdown"
      );
    },
    closeDropdownMenuSelf() {
      this.stateDropdownResultadosSistemicos === "hide-dropdown";
    },
    closeDropdownMenuOthers() {
      this.stateDropdownResultadosSistemicos = "hide-dropdown";
    }
  },
};
</script>

<style lang="sass" scoped>
.selo-branco-menu
  position: absolute
  text-align: center
  top: 228px
  width: 100%

.selo-branco-menu > img
  height: 144px
  width: 150px

.menu
  box-shadow: 0px 8px 8px 0px #00000029
  -webkit-box-shadow: 0px 8px 8px 0px #00000029
  -moz-box-shadow: 0px 8px 8px 0px #00000029
  display: flex
  min-height: 50px

.hide-item
  display: none

.show-item
  display: block

.content
  align-items: center
  display: flex
  font-family: 'Roboto', sans-serif
  gap: 24px
  justify-content: space-between
  max-width: 1248px
  margin: auto
  overflow: auto
  scrollbar-width: none
  & li
    list-style: none

.item-menu
  align-items: center
  border-bottom: 3px solid #f9f9f9 !important
  color: #777777
  display: flex
  font-size: 12px
  font-weight: 500
  gap: 8px
  height: 50px
  justify-content: center
  overflow: hidden
  padding: 4px
  text-align: center
  transition: 0.3s
  text-decoration: none !important
  text-transform: uppercase
  @media screen and (max-width: 1153px)
    height: 90px
  @media screen and (max-width: 992px)
    flex-wrap: wrap
    height: 90px
  @media screen and (max-width: 768px)
    overflow: visible
    width: 100px
  &:hover
    color: #1cabe2
    border-bottom: 3px solid #1cabe2 !important
    cursor: pointer
    transition: 0.3s

.gestao-resultado
  @media screen and (max-width: 1153px)
    display: none

@media screen and (max-width: 1153px)
  .label-menu
    width: 148px

.nav-link-selected
  border-bottom: 3px solid #1cabe2 !important
  color: #1cabe2 !important

select
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none

option
  text-align: left

.dropdown-resultados-sistemicos
  position: absolute
  max-width: 320px
  top: 350px
  @media screen and (max-width: 1153px)
    top: 390px
  @media screen and (max-width: 768px)
    right: 0px

.hide-dropdown
  display: none

.show-dropdown
  display: block

.option-dropdown
  color: #ffffff
  display: block
  min-height: 40px
  padding: 10px
  text-align: left
  transition: 0.1s
  width: 100%
  &:hover
    margin-left: 6px
    text-decoration: none
    transition: 0.1s

.desenvolvimento-infantil
  background-color: #df5d4e

.educacao
  background-color: #ca704d

.habitos
  background-color: #e3873e

.oportunidades
  background-color: #c09c25

.desenvolvimento-integral
  background-color: #4a9289

.prevencao
  background-color: #1c8076

.protecao
  background-color: #50a9c4

.horizon-bar::-webkit-scrollbar
  height: 0

.disabled
  color: #d7d7d7
  pointer-events: none

</style>
