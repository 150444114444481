<template>
  <footer>
    <img src="@/assets/rodape.svg" />
    <div class="footer-content">
      <div class="social-media-button">
        <div class="empty"></div>
        <div class="social-media">
          <div class="social-media-title">Acompanhe o UNICEF nas redes sociais:</div>
          <div class="social-media-icons">
            <a class="icon" target="_blank" :href="socialMedias[0]">
              <i class="fab fa-square-facebook"></i>
            </a>
            <a class="icon" target="_blank" :href="socialMedias[1]">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a class="icon" target="_blank" :href="socialMedias[2]">
              <i class="fab fa-twitter"></i>
            </a>
            <a class="icon" target="_blank" :href="socialMedias[3]">
              <i class="fab fa-youtube"></i>
            </a>
          </div>
        </div>
        <div class="button-suport-content">
          <a class="button-suport"
             target="_blank"
             :href="linkSuport"
          >
            Suporte ao município
          </a>
        </div>
      </div>

      <div class="social-media-button">
        <div class="button-suport-content-under">
          <a class="button-suport"
             target="_blank"
             :href="linkSuport"
          >
            Suporte ao município
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      title: "Footer",
      linkSuport: "https://docs.google.com/forms/d/e/1FAIpQLScVG0H9bE-h4bgEM-LDQ_1Ii2jl0fzrVThxaUBw7eVci-_66A/viewform",
      socialMedias: [
        'http://www.facebook.com',
        'http://www.instagram.com',
        'http://www.twitter.com',
        'http://www.youtube.com',
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
img
  width: 100%

.social-media-button
  background-color: #8e8378
  display: flex
  flex-wrap: wrap
  min-height: 5rem
  justify-content: center

.empty
  align-items: center
  display: flex
  justify-content: center
  width: 33%
  @media screen and (max-width: 530px)
    display: none

.social-media
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  width: 33%

.social-media-title
  color: #ffffff
  text-align: center

.social-media-icons
  color: #ffffff
  display: flex
  font-size: 20px
  justify-content: space-between
  margin-top: 16px
  width: 140px

.icon
  color: #ffffff

.button-suport-content
  align-items: center
  display: flex
  justify-content: center
  width: 33%

.button-suport-content
  @media screen and (max-width: 530px)
    display: none

.button-suport-content-under
  margin-top: 1rem
  display: none

.button-suport-content-under
  @media screen and (max-width: 530px)
    display: block

.button-suport
  align-items: center
  background-color: #ffffff
  border: none
  border-radius: 5px
  display: flex
  font-size: 12px
  height: 28px
  justify-content: center
  text-decoration: none !important
  width: 136px
  &:active
    color: blue
</style>
