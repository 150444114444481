<template>
  <header class="">
    <Banner />
    <Menu />
  </header>
</template>

<script>
import Banner from "./components/Banner.vue";
import Menu from "./components/Menu.vue";

export default {
  name: "Header",
  components: { Banner, Menu },
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped></style>
