<template>
  <div class="banner">
    <div class="content">
      <div class="top-header">
        <span v-if="showSeal" class="selo-branco">
          <img src="@/assets/selo-branco-junho.png" />
        </span>

        <!-- Descomentar para reexibir a bandeira -->
        <!-- <div class="flag">
          <span class="flag-content">
            <img src="@/assets/bandeira-header.svg" />
          </span>
        </div> -->
      </div>
      <div v-if="showTitle" class="content-cidade">
        <span class="cidade"> {{ this.municipio }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Endpoints from "../../../enums/endpoints.enum.js";

export default {
  name: "Banner",
  components: {},
  data() {
    return {
      municipios: null,
      municipioSelected: null,
      urlParams: this.$route.query,
      municipio: null,
      showSeal: null,
      showTitle: null
    };
  },
  watch:{
    $route (to){
      this.showSeal = to.path === "/" ? false : true;
      this.showTitle = to.path === "/" ? false : true;
    }
  },
  mounted() {
    const rotaAtual = this.$router.currentRoute.path.toString();

    this.showSeal = rotaAtual === "/" ? false : true;
    this.showTitle = rotaAtual === "/" ? false : true;

    this.getMunicipios(this.urlParams.uf);
  },
  methods: {
    fixMunicipioName(municipioName) {
      return municipioName.toLowerCase().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    },
    getMunicipios(uf) {
      const url = `${Endpoints.MUNICIPIOS}?uf=${uf}`;

      axios.get(url).then((response) => {
        this.municipios = Object.values(response.data.data);

        response.data.data.forEach((item) => {
          if (this.fixMunicipioName(item.name) == this.fixMunicipioName(this.urlParams.municipio)) {
            this.municipioSelected = item;
            this.municipio = this.municipioSelected.name ? `${this.municipioSelected.name} - ${this.municipioSelected.uf}` : "Selecione um município";
          }
        });
      });
    },
  }
};
</script>

<style lang="sass" scoped>
.banner
  background-color: #1cabe2
  color: #ffffff

.content
  background-image: linear-gradient(to right, #1cabe2, 10%, transparent 90%, #1cabe2), url("../../../assets/bg-header.png")
  background-size: cover
  display: flex
  flex-direction: column
  height: 300px
  justify-content: space-between
  margin: auto
  max-width: 1280px
  @media screen and (max-width: 768px)
    background-image: linear-gradient(to right, #1cabe2, 10%, transparent 90%, #1cabe2), url("../../../assets/bg-header-small.png")
  
.top-header
  display: flex
  justify-content: space-between

.flag
  color: #1cabe2
  display: none
  font-size: 11px
  position: absolute
  right: 80px
  text-align: center
  top: -40px
  @media screen and (max-width: 768px)
    display: block
  &:after
    content: "Participante Selo UNICEF"
    display: block
    position: absolute
    right: 1px
    top: 70px
    width: 80px

.flag-content
  display: block
  font-size: 12px
  font-weight: 500
  margin-top: 40px

.selo-branco > img
  margin: 30px 0 30px 110px
  width: 80px
  @media screen and (max-width: 768px)
    margin-left: 30px

.content-cidade
  margin-bottom: 30px

.cidade
  font-family: 'Roboto', sans-serif
  font-size: 45px
  font-weight: 500
  line-height: 1.2
  margin: 30px 0 30px 110px
  @media screen and (max-width: 768px)
    margin-left: 30px
  @media screen and (max-width: 375px)
    font-size: 35px
</style>
